/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import Seo from '../components/Seo';
import EpisodesLayout from '../components/EpisodesLayout';
import UnderdogSection from '../components/UnderdogSection';

const Underdog = () => (
  <div className="bg-background">
    <div className="mx-auto max-w-[85.375rem] bg-background shadow-xl shadow-[#000000ad]">
      <EpisodesLayout>
        <Seo title="Underdog" />
        <UnderdogSection />
      </EpisodesLayout>
    </div>
  </div>
);

export default Underdog;
