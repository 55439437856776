/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import MainHeader from '../components/MainHeader';

const EpisodesHeader = ({ setShowModal }) => (
  <nav className="inherit w-full z-40 transition duration-300 ease-in-out md:px-[3.125rem] px-[1.125rem]">
    <MainHeader setShowModal={setShowModal} />
  </nav>
);

export default EpisodesHeader;
