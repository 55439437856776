import React from 'react';

import PropTypes from 'prop-types';

import Footer from '../partial/Footer';
import SigninModal from './SigninModal';
import EpisodesHeader from '../partial/EpisodesHead';

const EpisodeLayout = ({ children }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className="font-Nunito min-h-screen overflow-scroll flex flex-col">
      <EpisodesHeader setShowModal={setShowModal} siteTitle="Episodes" />
      <main className="flex-grow">{children}</main>
      <Footer />
      <SigninModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

EpisodeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EpisodeLayout;
